import { TimelineState } from 'enums/TimeLine';

export const formValidateComplete = (neededKeys, obj): boolean => {
    const validateAllRequiredFields = neededKeys.map((keyName) => {
        return neededKeys.includes(keyName) && obj && typeof obj[keyName] !== 'undefined'
            ? obj[keyName].length > 0
            : false;
    });
    const validateAll = (validateAllRequiredFields: []): boolean => validateAllRequiredFields.every((v) => v === true);
    return validateAll(validateAllRequiredFields);
};
export const validateEmail = (email: string): boolean => {
    const whitespace = /\s/;
    const characters = /\S+@\S+\.\S+/;
    return !whitespace.test(email) && characters.test(email);
};
export const validateLast4SSN = (ssn: string): boolean => {
    return /^\d{4}$/.test(ssn);
};
export const validateDate = (date: string): boolean => {
    /* Check if passes regex - mm/dd/yy format, with 12 month max and 31 day max */
    const passesRegex = /(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\d\d/.test(date);
    if (!passesRegex) return false;
    /* Check if valid date */
    const testDate = new Date(date);
    if (isNaN(testDate.getTime())) return false;
    /* Check if elements of Date-typed version match the elements in passed-in string... this is to account for how date casting can overflow excessive days. (Ex: "04/31", upon conversion to Date-type, converts to 05/01) */
    const dayMonthYear = date.split('/').map((p) => parseInt(p, 10));
    if (dayMonthYear.length != 3 || dayMonthYear[1] != testDate.getDate()) return false;
    /* All tests passed */
    return true;
};
export const validateZip = (zip: string): boolean => {
    return /^\d{5}(?:[-\s]\d{4})?$/.test(zip);
};
export const validatePhoneNumber = (phoneNumber: string): boolean => {
    return /[(]\d{3}[)][ ]\d{3}[-]\d{4}/.test(phoneNumber);
};
export const validateClaimNumber = (claimNumber: string): boolean => {
    return /^[0-9]{10}$/.test(claimNumber);
};
/** Validate the price is a valid US price. Rejects strings with $ and commas. */
export const validatePriceUS = (price: string): boolean => {
    return /^\d+(\.\d{1,2})?$/.test(price);
};
/**
 * Given a string, if it is a valid number, then return the string with commas inserted at the corrected digits (ex: For the input "12345.6789", return "12,345.6789")
 * @param {string} inputString
 * @return string
 */
export const getNumberWithCommasInserted = (inputString: string): string => {
    if (!inputString || isNaN(Number(getStringWithoutCommas(inputString as string)))) {
        return inputString;
    }
    const splittedBeforeAndAfterDecimal = inputString.split('.', 2);
    const numberBeforeDecimal = splittedBeforeAndAfterDecimal[0];
    const numberNoCommas = getStringWithoutCommas(numberBeforeDecimal);
    const numberWithCorrectCommas = numberNoCommas.replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    if (splittedBeforeAndAfterDecimal.length > 1) {
        return numberWithCorrectCommas + '.' + splittedBeforeAndAfterDecimal[1];
    } else {
        return numberWithCorrectCommas;
    }
};
/** Given a string, if it is a valid number with all commas removed, then return the string with all commas removed (ex: For the input "1234,5.678,9", return "12345.6789") */
export const getNumberWithoutCommas = (inputString: string): string => {
    if (!inputString) return inputString;
    const stringWithoutCommas = getStringWithoutCommas(inputString);
    if (isNaN(Number(stringWithoutCommas))) {
        return inputString;
    }
    return stringWithoutCommas;
};
/** Given a string, return the same string with all commas removed */
export const getStringWithoutCommas = (stringToModify: string): string => {
    if (!stringToModify) return stringToModify;
    return stringToModify.replace(/,/g, '');
};
export const allFieldsComplete = (neededKeys, obj): boolean => {
    return obj && neededKeys
        ? neededKeys.every((key) => {
              return Object.keys(obj).includes(key) && obj[key] != null && obj[key] !== '';
          })
        : false;
};
export const allFieldsValid = (neededValidation: boolean[] | undefined): boolean => {
    return neededValidation && neededValidation.length > 0 && typeof neededValidation !== 'undefined'
        ? neededValidation.every((x) => {
              return x;
          })
        : true;
};
export const setAllStatusComplete = (progressArray): any => {
    progressArray.map((obj) => {
        obj.status = TimelineState.COMPLETE;
        return obj;
    });
};
export const errorMapIU = {
    emailError: 'Please enter a valid email address. (ex. example@trustage.com)',
    ssnError: 'Please enter last four digits of social security number. (ex. 1234)',
    dateError: 'Please enter valid date. (MM/DD/YYYY)',
    zipError: 'Please enter valid zip code. (ex. 12345 or 12345-6789)',
    phoneError: 'Please enter valid phone number. (ex. (555) 555-1234)',
    priceUSError: 'Please enter valid price. (ex. $123.45)'
};
/**
 * @name prependedInteger
 * @description Prepend single digit integer with zero
 * @param {number} integer
 * @return string - Prepend single digit integer with zero
 */
export const prependedInteger = (integer: number): string =>
    integer.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
    });
/**
 * @name formatCurrency
 * @description - Strips commas from strings, replace NaNs with 0, converts strings to number,
 * formats with en-US at two decimals to local string (could be extended for other locals)
 * @param {number | string} amountValueRaw - Compares type, Strips commas from strings
 * @return string - replace NaNs with 0, converts strings to number,
 * formats with en-US(default) to local string, at two decimals, both as a minimum and maximum value
 * (could be extended for other locals)
 */
export const formatCurrency = (amountValueRaw: number | string): string => {
    if (amountValueRaw == null) return '0.00';
    const amountValue = typeof amountValueRaw === 'string' ? amountValueRaw.replaceAll(',', '') : amountValueRaw;
    return !isNaN(typeof amountValue === 'string' ? parseFloat(amountValue) : amountValue)
        ? (typeof amountValue === 'string' ? parseFloat(amountValue) : amountValue).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          })
        : '0.00';
};
